// ================================================================================================
//     File Name: tables.scss
//     Description: Tables pages custom scss
//     ----------------------------------------------------------------------------------------------
//     Item name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
//     Author: PIXINVENT
//     Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

// table-white-space
.table-white-space {
  th, td {
    white-space: nowrap;
  }
}

// table th, td padding
.table {
  thead th{
    vertical-align: top;
  }
  th, td {
    padding: 1rem;
    vertical-align: middle;
  }
  //table heading font-size
  th{
    font-size: $table-th-font-size;
  }
}


// Table - inverse
.table-inverse {
  color: $white;
  background-color: $info;
}

// Table - hover animation
.table-hover-animation{
  background-color: $body-bg;
  thead{
    th{
      border-top: 2px solid $table-border-color;
      border-bottom: 0;
      background-color: $white;
    }
  }
  th, td {
    border: 0;
  }
  tbody{
    tr{
      transition: all 0.25s ease;
      background-color: $white;
      &:hover{
        transform:  translateY(-4px);
      }
    }
  }
}

// IE Specific CSS
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .table-hover-animation{
    tr{
      &:hover{
        border-bottom-color: $table-border-color;
      }
    }
  }
}
