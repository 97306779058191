/*=========================================================================================
  File Name: progress.scss
  Description: Extended bootstrap progress bar scss.
  ----------------------------------------------------------------------------------------
  Item name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: PIXINVENT
  Author URL: http://www.themeforest.net/user/pixinvent
  ----------------------------------------------------------------------------------------

  Modifiers:       `progress-lg`
                   `progress-md`
                   `progress-sm`
                   `progress-xs`

==========================================================================================*/

// Size Modifier
// ========================================================================

.progress {
  margin-bottom: 3rem;

  // margin for last progress bar

  &:last-child {
    margin-bottom: 1rem;
  }

  // progress bar sizes

  &.progress-xl {
    height: $progress-size-xl;
  }

  &.progress-lg {
    height: $progress-size-lg;
  }

  &.progress-md {
    height: $progress-size-md;
  }

  &.progress-sm {
    height: $progress-size-sm;
  }

  // progress bar radius

  .progress-bar {
    border-radius: $progress-border-radius;
  }
}
