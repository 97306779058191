// Component nav and nav/pill tabs

.nav {
  border-radius: 0.25rem;
  // for component navs
  // to wrap border around nav component
  &.wrap-border {
    border: 1px solid $nav-component-border-color;
    li.nav-header {
      margin: 0 0.5rem;
    }
    li.nav-item,
    div {
      padding: 2px .714rem;
    }
  }

  // Square Border
  &.square-border {
    border-radius: 0;
    .nav-item {
      .nav-link.active {
        border-radius: 0;
      }
    }
  }

  // for main menu toggle lock
  .modern-nav-toggle {
    padding: 1.571rem 0;
  }

  // nav pills
  &.nav-pills {
    margin-bottom: 1rem;
    // nav item of nav pill
    .nav-item {
      .nav-link {
        border-radius: .357rem;
        padding: .2rem 1rem;
        padding-top: .3rem;
        font-size: 0.98rem;
        margin-right: 0.5rem;
        color: $pure-black;
        &.active {
          color: $white;
        }
        &.disabled {
          color: $gray-600;
        }
        &.dropdown-toggle::after {
          top: 1px;
          left: 1px;
        }
      }

      &.dropdown {
        &.show{
          .nav-link {
            color: $white;
          }
          .dropdown-item{
            &.active{
              &:hover{
                color: $primary;
              }
            }
          }
        }
      }
    }

    // Justified Pills
    &.nav-justified {
      @include nav-justified;
      @include nav-tabs-justified;
      .nav-link {
        display: block;

        &.active {
          border: none;

          &:hover,
          &:focus {
            border: none;
          }
        }
      }
    }

    // Nav Active Bordered Pill
    &.nav-active-bordered-pill {
      .nav-item {
        a {
          &.nav-link {

            &.active {
              border: 1px solid $primary;
              border-radius: 0.25rem;
              color: $primary;
              background-color: $white;
            }
          }
        }
      }
    }
  }

  // Basic Nav Tabs
  &.nav-tabs {
    // box-shadow: -1px 11px 17px -10px rgba($pure-black, .04);
    border: none;
    margin-bottom: 1rem;
    position: relative;
    border-radius: 0;
    // Basic nav item
    .nav-item {
      position: relative;
      .nav-link {
        color: $body-color;
        font-size: 0.95rem;
        border: none;
        min-width: auto;
        font-weight: 450;
        padding: .61rem .635rem;
        border-radius: 0;
        &.active {
          border: none;
          position: relative;
          color: $primary;
          transition: all .2s ease;
          background-color: transparent;
          // transform: translateY(-2px);
          &:after{
            content: attr(data-before);
            height: 2px;
            width: 100%;
            left: 0;
            position: absolute;
            bottom: 0;
            top: 100%;
            background: linear-gradient(30deg, rgba($primary, 1), rgba($primary, .5)) !important;
            box-shadow: 0 0 8px 0 rgba($primary, .5) !important;
            transform: translateY(0px);
            transition:  all .2s linear;
          }
        }

        &.dropdown-toggle {
          &::after {
            top: 1px;
            left: 1px;
          }
        }

        &.disabled {
          color: $gray-600;
        }
      }
    }
    // Justified Tabs
    &.nav-justified {
      @include nav-justified;
      @include nav-tabs-justified;

      .nav-item {
        a.nav-link {
          display: block;
          border-radius: 0;

          &.active {
            border: none;
            background-color: $white;
          }
          &:hover {
            border-color: transparent;
          }
        }
      }
    }
  }
}

// Nav Tabs Vertical
.nav-vertical {
  overflow: hidden;
  .nav.nav-tabs {
    // nav item styles for vertical nav tabs
    .nav-item {
      .nav-link {
        border: none;
        margin-bottom: 0;

        &.active {
          border: none;
          border-radius: 0;

          &:after {
            transform: rotate(90deg);
            left: auto;
            right: -1.5rem;
            bottom: 100%;
            top: 1.25rem;
            width: 2.14rem;
          }
        }

        &:hover {
          border-right: none;
        }
      }
    }

    // vertical nav left tabs
    &.nav-left {
      float: left;
      display: table;
      margin-right: 1rem;

      ~.tab-content {
        .tab-pane {
          display: none;
          background-color: $white;
          overflow-y: auto;
          // padding-left: 1rem;
          &.active {
            display: block;
          }
        }
      }

    }

    // vertical right nav tabs
    &.nav-right {
      float: right;
      display: table;
      margin-left: 1rem;

      .nav-item {
        .nav-link {
          &.active {
            &:after {
              left: -1.5rem;
            }

          }
        }
      }

      ~.tab-content {
        .tab-pane {
          display: none;
          background-color: $white;
          overflow-y: auto;
          padding-right: 1rem;
          &.active {
            display: block;
          }
        }
      }
    }
  }
}

@media(max-width:576px) {
  // .nav-vertical .nav.nav-tabs.nav-left{
  //   float: none;
  // }
  .nav-vertical .nav.nav-tabs .nav-item .nav-link.active:after{
    height: 0;
  }
}